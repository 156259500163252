import React from "react"
import { Link } from "gatsby"
import { useStaticQuery, graphql } from "gatsby"

const Nav = ({className}) => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            menuLinks {
              name
              link
            }
          }
        }
      }
    `
  )

  return (
    <div className={className + " w-full"}>
       <div className="container flex flex-col items-center justify-center h-4/5 max-w-6xl px-8 mx-auto sm:justify-between xl:px-0">
        <nav className="w-full flex justify-center pt-5 mt-8 text-lg uppercase bg-transparent">
          {site.siteMetadata.menuLinks.map(link => (
              <Link
                className="text-white font-bold duration-100 mx-2 transition-color hover:text-opacity-40"
                key={link.slug}
                to={link.link}
              >
                {link.name}
              </Link>
          ))}
        </nav>
      </div>
    </div>
  )
}

export default Nav