import React from "react"
import { Link } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image";  
import Nav from "./nav"

const Layout = ({ location, title, subtitle, image, author, children }) => {
  const rootPath = `${__PATH_PREFIX__}/`
  const isRootPath = location.pathname === rootPath
  let img
  if (image != null) {
    img = (
      <GatsbyImage
        image={image.gatsbyImageData}
        className="absolute top-0 left-0 w-full h-full brightness-50"
        alt={title} />
    )
  } else {
    img = (
      <Link className="header-link-home" to="/">
        {title}
      </Link>
    )
  }

  return (
    <div className="global-wrapper" data-is-root-path={isRootPath}>
      <header className="relative w-full h-screen">
        {img}
        <div className="absolute w-full h-full top-0 z-100 flex flex-col items-stretch">
          <Nav className="" />
          <div className="text-white text-center flex-grow grid auto-rows-max place-content-center">
            <h1 className="font-sans uppercase font-bold text-4xl sm:text-6xl lg:text-7xl">{title}</h1>
            <h2 className="italic font-serif text-lg sm:text-2xl sm:leading-10 font-medium mb-10 sm:mb-11">{subtitle}</h2>
          </div>
          <div className="w-full h-16">
            <a href="#main">
              <svg
                className="text-white h-3/4   mx-auto"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path fillRule="evenodd" d="M15.707 4.293a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0l-5-5a1 1 0 011.414-1.414L10 8.586l4.293-4.293a1 1 0 011.414 0zm0 6a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0l-5-5a1 1 0 111.414-1.414L10 14.586l4.293-4.293a1 1 0 011.414 0z" clipRule="evenodd" />
              </svg>
            </a>
          </div>
        </div>
      </header>
      <main id="main" className="flex flex-col h-full font-serif">
        {children}
      </main>
      <footer>
        © {new Date().getFullYear()}, Kinoko Project
      </footer>
    </div>
  )
}

export default Layout
