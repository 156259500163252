import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const Index = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata?.title || `Title`

  return (
    <Layout
      location={location}
      title={siteTitle}
      subtitle={data.site.siteMetadata?.description}
      image={data.file.childImageSharp}
    >
      <SEO title={data.site.siteMetadata.description} />
    </Layout>
  )
}

export default Index

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        description
      }
    }
    file(relativePath: { eq: "home.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          quality: 60,
          layout: FULL_WIDTH,
          formats: [AUTO, WEBP]
        )
      }
    }
  }
`
